/* eslint-disable jsx-a11y/label-has-associated-control */
/**
 * Type: ページ
 * What: 学祭協賛申し込みフォーム作成
 */
import React, { useEffect, useState } from 'react';
import { navigate, graphql, Link } from 'gatsby';
import axios from 'axios';
import { scroller } from 'react-scroll';
import { useForm } from 'react-hook-form';
import DatePicker, { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';
import moment from 'moment/moment';
import Layout from '../../../components/Layout';
import SEO from '../../../components/Seo';
import BreadcrumbComponent from '../../../components/Breadcrumb';
import '../../../sass/components/_form.scss';
import FormText from './FormText';
import Confirm from './Confirm';
import { Button } from '../../../components/Btn';
import 'react-datepicker/dist/react-datepicker.css';
import googleBnr from '../../../images/bnr/bnr-google.png';
import Image from '../../../util/Image';

registerLocale('ja', ja);

const pageTitle = '学園祭協賛のお知らせ';
const pageSlug = 'inquiry';

export const query = graphql`
  {
    site(siteMetadata: {}) {
      siteMetadata {
        endPointApiGakusaiForm
      }
    }
  }
`;

// Data Props Template
const useFormPage = ({ data }) => {
  const dt = new Date();
  const { register, handleSubmit, errors, reset, getValues } = useForm();
  const [Token, setToken] = useState(false); // トークン
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false); // 確認画面の表示
  const hideConfirmation = () => setIsConfirmationVisible(false);
  const [Formdata, setFormdata] = useState([]); // 確認画面用フォームデータ
  const [open, setOpen] = useState(false); // モーダル展開
  const [submit, setSubmit] = useState(false); // 送信
  const [errorMessage, setErrorMessage] = useState(false); // エラーメッセージオブジェクト
  const [errorMessageCustom] = useState(['', '', '']); // カスタムエラーメッセージ配列

  const [selectedDate, setSelectedDate] = useState(dt.setDate(dt.getDate()));
  const [startDate, setStartDate] = useState(dt.setDate(dt.getDate() + 28));
  const [howToPosting2Date, setHowToPosting2Date] = useState('');
  const [howToPosting3Date, setHowToPosting3Date] = useState('');
  const [howToPosting1, setHowToPosting1] = useState('');
  const [howToPosting2, setHowToPosting2] = useState('');
  const [howToPosting3, setHowToPosting3] = useState('');
  const [flyerIsManuscript, setFlyerIsManuscript] = useState('');
  const [snsIsManuscript, setSnsIsManuscript] = useState('');
  const [zip, setZip] = useState('');
  const [place, setPlace] = useState('');

  const APIURL = data.site.siteMetadata.endPointApiGakusaiForm;
  const formValidate = `${APIURL}/gakusai_validate`;
  const formSubmit = `${APIURL}/gakusai_submit`;
  const formToken = `${APIURL}/form_token`;

  const GOOGLE_MAP_API = (zipValue) => {
    const endpoint = `https://maps.googleapis.com/maps/api/geocode/json?address=${zipValue}&language=ja&components=country:JP&key=AIzaSyB0qLYYrHcMr2qCmb-7x8jtmE-P9J_CWWM`;
    return endpoint;
  };

  // 申込日
  const handleDate = (e) => {
    setSelectedDate(e);
  };

  // 学園祭開催期日
  const handleDateStart = (e) => {
    setStartDate(e);
  };

  // 提出期限
  const handleDateHowToPosting2 = (e) => {
    setHowToPosting2Date(e);
  };

  // 提出期限
  const handleDateHowToPosting3 = (e) => {
    setHowToPosting3Date(e);
  };

  // チェック状態を取得してStateへ
  const handleChecked1 = (e) => {
    if (e.target.checked) {
      setHowToPosting1(e.target.name);
    } else {
      setHowToPosting1('');
    }
  };
  const handleChecked2 = (e) => {
    if (e.target.checked) {
      setHowToPosting2(e.target.name);
    } else {
      setHowToPosting2('');
    }
  };
  const handleChecked3 = (e) => {
    if (e.target.checked) {
      setHowToPosting3(e.target.name);
    } else {
      setHowToPosting3('');
    }
  };

  // エラーメッセージにスクロール
  const scrollToTarget = () => {
    scroller.scrollTo('errorMsg', {
      duration: 800,
      delay: 0,
      offset: -70,
      smooth: 'easeOutQuint',
    });
  };

  // 郵便番号自動入力
  const SetZipHandler = () => {
    if (!zip) {
      // eslint-disable-next-line no-alert
      window.alert('郵便番号を入力してくださいs');
      return false;
    }
    return axios
      .get(GOOGLE_MAP_API(zip), {
        timeout: 20000,
      })
      .then((res) => {
        const obj = res.data.results[0].address_components;
        if (res.data.status !== 'OK') {
          // eslint-disable-next-line no-alert
          window.alert('郵便番号を取得できませんでした');
          return false;
        }
        if (zip.length < 5 || obj.length < 2) {
          // eslint-disable-next-line no-alert
          window.alert('正しい郵便番号を入力してください');
          return false;
        }

        const result = obj.filter((o) => o.short_name !== 'JP');
        const locality1 = result[1] ? result[1].long_name : '';
        const locality2 = result[2] ? result[2].long_name : '';
        const locality3 = result[3] ? result[3].long_name : '';
        const locality4 = result[4] ? result[4].long_name : '';
        const locality5 = result[5] ? result[5].long_name : '';
        const locality6 = result[6] ? result[6].long_name : '';
        return setPlace(
          `${locality6}${locality5}${locality4}${locality3}${locality2}${locality1}`
        );
      })
      .catch((err) => {
        // eslint-disable-next-line no-alert
        window.alert('郵便番号を取得できませんでした');
      });
  };

  // 初回アクセス時にトークン取得
  const tokenFetch = () => {
    axios
      .post(formToken, { timeout: 20000 })
      .then((res) => {
        if (res.data.errors.length === 0) {
          // エラーメッセージがあるかどうか 配列が空なら成功
          setToken(res.data.token);
        } else {
          setErrorMessage(res.data.errors);
        }
      })
      .catch((err) => {
        // サーバーエラー
        setErrorMessage(['サーバーエラーが発生しました。']);
      });
  };

  // バリデーション
  const validationPost = () => {
    // データ整形
    const items = [howToPosting1, howToPosting2, howToPosting3].filter(Boolean);
    setSubmit(true);
    const values = getValues();
    if (items.length) {
      values.id_10_how_to_posting = items;
    } else {
      values.id_10_how_to_posting = [];
    }
    values.id_01_application_date = moment(selectedDate).format('Y-M-D');
    values.id_04_school_festival_start_date = moment(startDate).format('Y-M-D');
    values.id_20_flyer_manuscript_deadline = moment(howToPosting2Date).format(
      'Y-M-D'
    );
    values.id_26_sns_manuscript_deadline = moment(howToPosting3Date).format(
      'Y-M-D'
    );
    if (howToPosting1 === '') {
      values.id_11_signboard_height = '';
      values.id_12_signboard_width = '';
      values.id_13_signboard_file_ext = '';
    }
    if (
      (howToPosting1 && values.id_11_signboard_height === '') ||
      (howToPosting1 && values.id_12_signboard_width === '') ||
      (howToPosting1 && values.id_13_signboard_file_ext === '')
    ) {
      errorMessageCustom[0] = '看板の掲出: 必要サイズは必須項目です';
    } else {
      errorMessageCustom[0] = '';
    }
    if (howToPosting2 === '') {
      values.id_14_flyer_height = '';
      values.id_15_flyer_width = '';
      values.id_16_flyer_file_ext = '';
      values.id_17_flyer_number_of_circulation = '';
      values.id_18_flyer_is_manuscript = '';
      values.id_19_flyer_number_of_chars_manuscript = '';
      values.id_20_flyer_manuscript_deadline = '';
    }
    if (
      (howToPosting2 && values.id_14_flyer_height === '') ||
      (howToPosting2 && values.id_15_flyer_width === '') ||
      (howToPosting2 && values.id_16_flyer_file_ext === '') ||
      (howToPosting2 && values.id_17_flyer_number_of_circulation === '') ||
      (howToPosting2 && values.id_18_flyer_is_manuscript === '') ||
      (howToPosting2 &&
        values.id_18_flyer_is_manuscript === '必要' &&
        values.id_19_flyer_number_of_chars_manuscript === '') ||
      (howToPosting2 &&
        values.id_20_flyer_manuscript_deadline === 'Invalid date')
    ) {
      errorMessageCustom[1] =
        'パンフレット／チラシ掲載: 必要サイズ・詳細は必須項目です';
    } else {
      errorMessageCustom[1] = '';
    }
    if (howToPosting3 === '') {
      values.id_21_sns_height = '';
      values.id_22_sns_width = '';
      values.id_23_sns_file_ext = '';
      values.id_24_sns_is_manuscript = '';
      values.id_25_sns_number_of_chars_manuscript = '';
      values.id_26_sns_manuscript_deadline = '';
      errorMessageCustom[2] = '';
    }
    if (
      (howToPosting3 && values.id_21_sns_height === '') ||
      (howToPosting3 && values.id_22_sns_width === '') ||
      (howToPosting3 && values.id_23_sns_file_ext === '') ||
      (howToPosting3 && values.id_24_sns_is_manuscript === '') ||
      (howToPosting3 &&
        values.id_24_sns_is_manuscript === '必要' &&
        values.id_25_sns_number_of_chars_manuscript === '') ||
      (howToPosting3 && values.id_26_sns_manuscript_deadline === 'Invalid date')
    ) {
      errorMessageCustom[2] = 'SNSでの紹介: 必要サイズ・詳細は必須項目です';
    } else {
      errorMessageCustom[2] = '';
    }

    values.token = Token;

    axios
      .post(formValidate, values, {
        timeout: 20000,
      })
      .then((res) => {
        if (
          res.data.errors.length === 0 &&
          errorMessageCustom[0] === '' &&
          errorMessageCustom[1] === '' &&
          errorMessageCustom[2] === ''
        ) {
          // エラーメッセージがあるかどうか 配列が空なら成功
          confirmTable(values);
          setErrorMessage(false);
          setIsConfirmationVisible(true);
          setTimeout(() => {
            setOpen(true);
            setSubmit(false);
          }, 300);
        } else {
          hideConfirmation();
          setSubmit(false);
          setErrorMessage(res.data.errors);
          scrollToTarget();
        }
      })
      .catch((err) => {
        // サーバーエラー
        setSubmit(false);
        setErrorMessage(['サーバーエラーが発生しました。']);
        // setErrorMessage(err.response.data.message);
      });
  };

  // フォーム送信
  const dataPost = () => {
    // データ整形
    const items = [howToPosting1, howToPosting2, howToPosting3].filter(Boolean);
    setSubmit(true);
    const values = getValues();
    if (items.length) {
      values.id_10_how_to_posting = items;
    } else {
      values.id_10_how_to_posting = [];
    }
    values.id_01_application_date = moment(selectedDate).format('Y-M-D');
    values.id_04_school_festival_start_date = moment(startDate).format('Y-M-D');
    values.id_20_flyer_manuscript_deadline = moment(howToPosting2Date).format(
      'Y-M-D'
    );
    values.id_26_sns_manuscript_deadline = moment(howToPosting3Date).format(
      'Y-M-D'
    );
    if (howToPosting1 === '') {
      values.id_11_signboard_height = '';
      values.id_12_signboard_width = '';
      values.id_13_signboard_file_ext = '';
    }
    if (howToPosting2 === '') {
      values.id_14_flyer_height = '';
      values.id_15_flyer_width = '';
      values.id_16_flyer_file_ext = '';
      values.id_17_flyer_number_of_circulation = '';
      values.id_18_flyer_is_manuscript = '';
      values.id_19_flyer_number_of_chars_manuscript = '';
      values.id_20_flyer_manuscript_deadline = '';
    }
    if (howToPosting3 === '') {
      values.id_21_sns_height = '';
      values.id_22_sns_width = '';
      values.id_23_sns_file_ext = '';
      values.id_24_sns_is_manuscript = '';
      values.id_25_sns_number_of_chars_manuscript = '';
      values.id_26_sns_manuscript_deadline = '';
    }

    values.token = Token;
    setSubmit(true);
    // console.log(JSON.stringify(values));
    axios
      .post(formSubmit, values, {
        timeout: 20000,
      })
      .then((res) => {
        if (res.data.errors.length === 0) {
          // エラーメッセージがあるかどうか 配列が空なら成功
          setOpen(false);
          setSubmit(false);
          setTimeout(() => {
            navigate(
              // 完了ページへ
              '/inquiry/completed/',
              { replace: false }
            );
          }, 600);
        } else {
          hideConfirmation();
          scrollToTarget();
          setSubmit(false);
          setErrorMessage(res.data.errors);
        }
      })
      .catch((err) => {
        hideConfirmation();
        scrollToTarget();
        setSubmit(false);
        setErrorMessage(['サーバーエラーが発生しました。']);
      });
  };

  // モーダル非表示
  const modalClose = () => {
    setOpen(false);
    setTimeout(() => {
      hideConfirmation();
    }, 600);
  };

  // 確認画面のテーブルを自動で組み立て
  const confirmTable = (values) => {
    const temp = [];
    const fixedValue = Object.keys(values);
    fixedValue.sort();
    for (const key of fixedValue) {
      switch (key) {
        case 'id_01_application_date':
          temp.push(
            <tr>
              <th>申込日</th>
              <td wovn-ignore="true">{values[key]}</td>
            </tr>
          );
          break;
        case 'id_02_school_name':
          temp.push(
            <tr>
              <th>学校名</th>
              <td wovn-ignore="true">{values[key]}</td>
            </tr>
          );
          break;
        case 'id_03_school_festival_name':
          temp.push(
            <tr>
              <th>学園祭名</th>
              <td wovn-ignore="true">{values[key]}</td>
            </tr>
          );
          break;
        case 'id_04_school_festival_start_date':
          temp.push(
            <tr>
              <th>学園祭開催期日</th>
              <td wovn-ignore="true">{values[key]}</td>
            </tr>
          );
          break;
        case 'id_05_in_charge_person_name':
          temp.push(
            <tr>
              <th>実行委員担当者名（主・副担当者）</th>
              <td wovn-ignore="true">{values[key]}</td>
            </tr>
          );
          break;
        case 'id_06_telephone':
          temp.push(
            <tr>
              <th>連絡先（電話番号）</th>
              <td wovn-ignore="true">{values[key]}</td>
            </tr>
          );
          break;
        case 'id_07_post_code':
          temp.push(
            <tr>
              <th>郵便番号</th>
              <td wovn-ignore="true">{values[key]}</td>
            </tr>
          );
          break;
        case 'id_08_address':
          temp.push(
            <tr>
              <th>住所</th>
              <td wovn-ignore="true">{values[key]}</td>
            </tr>
          );
          break;
        case 'id_09_email':
          temp.push(
            <tr>
              <th>メールアドレス</th>
              <td wovn-ignore="true">{values[key]}</td>
            </tr>
          );
          break;
        case 'id_10_how_to_posting':
          temp.push(
            <tr>
              <th>学園祭での掲出方法</th>
              <td wovn-ignore="true">{values[key]}</td>
            </tr>
          );
          break;
        case 'id_11_signboard_height':
          if (howToPosting1) {
            temp.push(
              <tr>
                <th>看板の掲出: 必要サイズ縦</th>
                <td wovn-ignore="true">{values[key]}</td>
              </tr>
            );
          }
          break;
        case 'id_12_signboard_width':
          if (howToPosting1) {
            temp.push(
              <tr>
                <th>看板の掲出: 必要サイズ横</th>
                <td wovn-ignore="true">{values[key]}</td>
              </tr>
            );
          }
          break;
        case 'id_13_signboard_file_ext':
          if (howToPosting1) {
            temp.push(
              <tr>
                <th>看板の掲出: 指定ファイル</th>
                <td wovn-ignore="true">{values[key]}</td>
              </tr>
            );
          }
          break;
        case 'id_14_flyer_height':
          if (howToPosting2) {
            temp.push(
              <tr>
                <th>パンフレット／チラシ掲載: 必要サイズ縦</th>
                <td wovn-ignore="true">{values[key]}</td>
              </tr>
            );
          }
          break;
        case 'id_15_flyer_width':
          if (howToPosting2) {
            temp.push(
              <tr>
                <th>パンフレット／チラシ掲載: 必要サイズ横</th>
                <td wovn-ignore="true">{values[key]}</td>
              </tr>
            );
          }
          break;
        case 'id_16_flyer_file_ext':
          if (howToPosting2) {
            temp.push(
              <tr>
                <th>パンフレット／チラシ掲載: 指定ファイル</th>
                <td wovn-ignore="true">{values[key]}</td>
              </tr>
            );
          }
          break;
        case 'id_17_flyer_number_of_circulation':
          if (howToPosting2) {
            temp.push(
              <tr>
                <th>パンフレット／チラシ掲載: 発行部数</th>
                <td wovn-ignore="true">{values[key]}</td>
              </tr>
            );
          }
          break;
        case 'id_18_flyer_is_manuscript':
          if (howToPosting2) {
            temp.push(
              <tr>
                <th>パンフレット／チラシ掲載: 掲載原稿有無</th>
                <td wovn-ignore="true">{values[key]}</td>
              </tr>
            );
          }
          break;
        case 'id_19_flyer_number_of_chars_manuscript':
          if (howToPosting2) {
            temp.push(
              <tr>
                <th>パンフレット／チラシ掲載: 原稿文字数</th>
                <td wovn-ignore="true">{values[key]}</td>
              </tr>
            );
          }
          break;
        case 'id_20_flyer_manuscript_deadline':
          if (howToPosting2) {
            temp.push(
              <tr>
                <th>パンフレット／チラシ掲載: 原稿締切日</th>
                <td wovn-ignore="true">{values[key]}</td>
              </tr>
            );
          }
          break;
        case 'id_21_sns_height':
          if (howToPosting3) {
            temp.push(
              <tr>
                <th>SNSでの紹介: 必要サイズ縦</th>
                <td wovn-ignore="true">{values[key]}</td>
              </tr>
            );
          }
          break;
        case 'id_22_sns_width':
          if (howToPosting3) {
            temp.push(
              <tr>
                <th>SNSでの紹介: 必要サイズ横</th>
                <td wovn-ignore="true">{values[key]}</td>
              </tr>
            );
          }
          break;
        case 'id_23_sns_file_ext':
          if (howToPosting3) {
            temp.push(
              <tr>
                <th>SNSでの紹介: 指定ファイル</th>
                <td wovn-ignore="true">{values[key]}</td>
              </tr>
            );
          }
          break;
        case 'id_24_sns_is_manuscript':
          if (howToPosting3) {
            temp.push(
              <tr>
                <th>SNSでの紹介: 掲載原稿有無</th>
                <td wovn-ignore="true">{values[key]}</td>
              </tr>
            );
          }
          break;
        case 'id_25_sns_number_of_chars_manuscript':
          if (howToPosting3) {
            temp.push(
              <tr>
                <th>SNSでの紹介: 原稿文字数</th>
                <td wovn-ignore="true">{values[key]}</td>
              </tr>
            );
          }
          break;
        case 'id_26_sns_manuscript_deadline':
          if (howToPosting3) {
            temp.push(
              <tr>
                <th>SNSでの紹介: 原稿締切日</th>
                <td wovn-ignore="true">{values[key]}</td>
              </tr>
            );
          }
          break;
        case 'id_27_use_of_sponsored_products':
          temp.push(
            <tr>
              <th>協賛品の用途</th>
              <td wovn-ignore="true">{values[key]}</td>
            </tr>
          );
          break;
        case 'id_28_expected_number_of_visitors':
          temp.push(
            <tr>
              <th>見込み来場者数</th>
              <td wovn-ignore="true">{values[key]}</td>
            </tr>
          );
          break;
        case 'id_29_remarks':
          temp.push(
            <tr>
              <th>備考、質問等</th>
              <td wovn-ignore="true">{values[key]}</td>
            </tr>
          );
          break;
        default:
          break;
      }
    }
    setFormdata(temp);
  };

  useEffect(() => {
    tokenFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout pageSlug={pageSlug}>
      <SEO title={pageTitle} />
      <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />
      <section className="section">
        <div className="container">
          <FormText />
          <p className="notification sub-desc" id="errorMsg">
            以下の項目をすべて入力のうえ送信してください。
          </p>
          <div className="inner-slim">
            <div className="notification is-danger is-empty">
              {errorMessage &&
                Object.keys(errorMessage).map((key) => (
                  <p className="error-list" key={key}>
                    {errorMessage[key]}
                  </p>
                ))}
              {errorMessageCustom[0] !== '' && (
                <p className="error-list">{errorMessageCustom[0]}</p>
              )}
              {errorMessageCustom[1] !== '' && (
                <p className="error-list">{errorMessageCustom[1]}</p>
              )}
              {errorMessageCustom[2] !== '' && (
                <p className="error-list">{errorMessageCustom[2]}</p>
              )}
            </div>
            {isConfirmationVisible && Token && (
              <Confirm
                open={open}
                Formdata={Formdata}
                modalClose={modalClose}
                submit={submit}
                dataPost={dataPost}
              />
            )}

            <form onSubmit={handleSubmit()} name="contactForm">
              {/* INPUT01: id_01_application_date */}
              <div className="field">
                <label className="label label-text" data-label="申込日">
                  申込日 <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div
                  className="control is-expanded has-icons-left has-icons-right date-wrap"
                  wovn-ignore="true"
                >
                  <input
                    type="hidden"
                    value={moment(selectedDate).format('Y-M-D')}
                  />
                  <DatePicker
                    className="input"
                    dateFormat="yyyy年MM月dd日"
                    selected={selectedDate}
                    onChange={(e) => handleDate(e)}
                    locale="ja"
                    name="id_01_application_date"
                    showDisabledMonthNavigation
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-calendar-check" />
                  </span>
                </div>
                {errors.id_01_application_date && (
                  <p className="help is-alert">
                    {errors.id_01_application_date.message}
                  </p>
                )}
              </div>
              {/* INPUT02: id_02_school_name */}
              <div className="field">
                <label className="label" data-label="学校名">
                  学校名 <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div
                  className="control is-expanded has-icons-left has-icons-right"
                  wovn-ignore="true"
                >
                  <input
                    className={
                      errors.id_02_school_name ? 'input error' : 'input'
                    }
                    type="text"
                    placeholder="学校名"
                    name="id_02_school_name"
                    ref={register({
                      required: '必須項目です',
                    })}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-graduation-cap" />
                  </span>
                </div>
                {errors.id_02_school_name && (
                  <p className="help is-alert">
                    {errors.id_02_school_name.message}
                  </p>
                )}
              </div>
              {/* INPUT03: id_03_school_festival_name */}
              <div className="field">
                <label className="label" data-label="学園祭名">
                  学園祭名{' '}
                  <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div
                  className="control is-expanded has-icons-left has-icons-right"
                  wovn-ignore="true"
                >
                  <input
                    className={
                      errors.id_03_school_festival_name
                        ? 'input error'
                        : 'input'
                    }
                    type="text"
                    placeholder="学園祭名"
                    name="id_03_school_festival_name"
                    ref={register({
                      required: '必須項目です',
                    })}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-book" />
                  </span>
                </div>
                {errors.id_03_school_festival_name && (
                  <p className="help is-alert">
                    {errors.id_03_school_festival_name.message}
                  </p>
                )}
              </div>
              {/* INPUT04: id_04_school_festival_start_date */}
              <div className="field">
                <label className="label" data-label="学園祭開催期日">
                  学園祭開催期日{' '}
                  <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div
                  className="control is-expanded has-icons-left has-icons-right date-wrap"
                  wovn-ignore="true"
                >
                  <input
                    type="hidden"
                    value={moment(startDate).format('Y-M-D')}
                  />
                  <DatePicker
                    className="input"
                    dateFormat="yyyy年MM月dd日"
                    selected={startDate}
                    minDate={dt}
                    onChange={(e) => handleDateStart(e)}
                    locale="ja"
                    name="id_04_school_festival_start_date"
                    showDisabledMonthNavigation
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-calendar-check" />
                  </span>
                </div>
                {errors.id_04_school_festival_start_date && (
                  <p className="help is-alert">
                    {errors.id_04_school_festival_start_date.message}
                  </p>
                )}
              </div>
              {/* INPUT05: id_05_in_charge_person_name */}
              <div className="field">
                <label
                  className="label"
                  data-label="実行委員担当者名（主・副担当者）"
                >
                  実行委員担当者名（主・副担当者）{' '}
                  <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div
                  className="control is-expanded has-icons-left has-icons-right"
                  wovn-ignore="true"
                >
                  <input
                    className={
                      errors.id_05_in_charge_person_name
                        ? 'input error'
                        : 'input'
                    }
                    type="text"
                    placeholder="実行委員担当者名（主・副担当者）"
                    name="id_05_in_charge_person_name"
                    autoComplete="name"
                    ref={register({
                      required: '必須項目です',
                    })}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-user" />
                  </span>
                </div>
                {errors.id_05_in_charge_person_name && (
                  <p className="help is-alert">
                    {errors.id_05_in_charge_person_name.message}
                  </p>
                )}
              </div>
              {/* INPUT06: id_06_telephone */}
              <div className="field">
                <label className="label" data-label="連絡先（電話番号）">
                  連絡先（電話番号）{' '}
                  <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div
                  className="control has-icons-left has-icons-right"
                  wovn-ignore="true"
                >
                  <input
                    className={errors.id_06_telephone ? 'input error' : 'input'}
                    type="tel"
                    placeholder="連絡先（電話番号）"
                    name="id_06_telephone"
                    ref={register({
                      required: '必須項目です',
                    })}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-phone-square" />
                  </span>
                </div>
                <p className="help">半角（ハイフン無し）で入力ください。</p>
                {errors.id_06_telephone && (
                  <p className="help is-alert">
                    {errors.id_06_telephone.message}
                  </p>
                )}
              </div>
              {/* INPUT07: id_07_post_code */}
              <div className="form-wrap">
                <div className="field">
                  <label className="label" data-label="郵便番号">
                    <b>郵便番号</b>{' '}
                    <span className="tag is-rounded is-danger">必須</span>
                  </label>
                  <div
                    className="control has-icons-left has-icons-right"
                    wovn-ignore="true"
                  >
                    <input
                      className={
                        errors.id_07_post_code ? 'input error' : 'input'
                      }
                      type="tel"
                      placeholder="郵便番号"
                      name="id_07_post_code"
                      autoComplete="postal-code"
                      onChange={(e) => setZip(e.target.value)}
                      ref={register({
                        required: '必須項目です',
                      })}
                    />
                    <span className="icon is-small is-left">
                      <i className="fa fa-map-marker" />
                    </span>
                  </div>
                  <p className="help">半角（ハイフン無し）で入力ください。</p>
                  {errors.id_07_post_code && (
                    <p className="help is-alert">
                      {errors.id_07_post_code.message}
                    </p>
                  )}
                </div>
                <div className="zip-btns">
                  <button type="button" onClick={SetZipHandler}>
                    住所を自動で入力する
                  </button>
                  <img src={googleBnr} alt="" />
                </div>
              </div>
              {/* INPUT08: id_08_address */}
              <div className="field">
                <label className="label">
                  住所 <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div
                  className="control is-expanded has-icons-left has-icons-right"
                  wovn-ignore="true"
                >
                  <input
                    className={errors.id_08_address ? 'input error' : 'input'}
                    type="text"
                    placeholder="住所"
                    name="id_08_address"
                    value={place}
                    onChange={(e) => setPlace(e.target.value)}
                    ref={register({
                      required: '必須項目です',
                    })}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-map-marker" />
                  </span>
                </div>
                {errors.id_08_address && (
                  <p className="help is-alert">
                    {errors.id_08_address.message}
                  </p>
                )}
              </div>
              {/* INPUT09: id_09_email */}
              <div className="field">
                <label className="label">
                  メールアドレス{' '}
                  <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div
                  className="control has-icons-left has-icons-right"
                  wovn-ignore="true"
                >
                  <input
                    className={errors.id_09_email ? 'input error' : 'input'}
                    type="email"
                    placeholder="メールアドレス"
                    name="id_09_email"
                    autoComplete="id_09_email"
                    ref={register({
                      required: '必須項目です',
                    })}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-envelope" />
                  </span>
                </div>
                {errors.id_09_email && (
                  <p className="help is-alert">{errors.id_09_email.message}</p>
                )}
              </div>
              <hr />
              {/* INPUT10: id_10_how_to_posting */}
              <div className="field">
                <label className="label">
                  学園祭での掲出方法{' '}
                  <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div className="control" wovn-ignore="true">
                  <label className="checkbox smc">
                    <input
                      type="checkbox"
                      name="看板の掲出"
                      onChange={(e) => handleChecked1(e)}
                    />
                    看板の掲出
                  </label>
                  <label className="checkbox smc">
                    <input
                      type="checkbox"
                      name="パンフレット／チラシ掲載"
                      onChange={(e) => handleChecked2(e)}
                    />
                    パンフレット／チラシ掲載
                  </label>
                  <label className="checkbox smc">
                    <input
                      type="checkbox"
                      name="SNSでの紹介"
                      onChange={(e) => handleChecked3(e)}
                    />
                    SNSでの紹介
                  </label>
                </div>
                {errors.id_10_how_to_posting && (
                  <p className="help is-alert">
                    {errors.id_10_how_to_posting.message}
                  </p>
                )}
              </div>
              {/* INPUT11: id_11_signboard_height */}
              <label className={`label ${howToPosting1 === '' ? 'hide' : ''}`}>
                看板の掲出: 必要サイズ{' '}
                <span className="tag is-rounded is-danger">必須</span>
              </label>
              <div
                className={`form-wrap child ${
                  howToPosting1 === '' ? 'hide' : ''
                }`}
              >
                <div className="field">
                  <label className="label">縦</label>
                  <div className="control" wovn-ignore="true">
                    <input
                      className={
                        errors.id_11_signboard_height ? 'input error' : 'input'
                      }
                      type="text"
                      placeholder="ピクセル"
                      name="id_11_signboard_height"
                      ref={register(
                        howToPosting1.length > 0 && {
                          required: '必須項目です',
                        }
                      )}
                    />
                    <span>PX</span>
                  </div>
                  {errors.id_11_signboard_height && (
                    <p className="help is-alert">
                      {errors.id_11_signboard_height.message}
                    </p>
                  )}
                </div>
                {/* INPUT12: id_12_signboard_width */}
                <div className="field">
                  <label className="label">横</label>
                  <div className="control" wovn-ignore="true">
                    <input
                      className={
                        errors.id_12_signboard_width ? 'input error' : 'input'
                      }
                      type="text"
                      placeholder="ピクセル"
                      name="id_12_signboard_width"
                      ref={register(
                        howToPosting1.length > 0 && {
                          required: '必須項目です',
                        }
                      )}
                    />
                    <span>PX</span>
                  </div>
                  {errors.id_12_signboard_width && (
                    <p className="help is-alert">
                      {errors.id_12_signboard_width.message}
                    </p>
                  )}
                </div>
                {/* INPUT13: id_13_signboard_file_ext */}
                <div className="field">
                  <label className="label">指定ファイル</label>
                  <div className="control" wovn-ignore="true">
                    <input
                      className={
                        errors.id_13_signboard_file_ext
                          ? 'input error'
                          : 'input'
                      }
                      type="text"
                      placeholder="JPEG/PNGなど"
                      name="id_13_signboard_file_ext"
                      ref={register(
                        howToPosting1.length > 0 && {
                          required: '必須項目です',
                        }
                      )}
                    />
                  </div>
                  {errors.id_13_signboard_file_ext && (
                    <p className="help is-alert">
                      {errors.id_13_signboard_file_ext.message}
                    </p>
                  )}
                </div>
              </div>
              {/* INPUT14: id_14_flyer_height */}
              <label className={`label ${howToPosting2 === '' ? 'hide' : ''}`}>
                パンフレット／チラシ掲載: 必要サイズ・詳細{' '}
                <span className="tag is-rounded is-danger">必須</span>
              </label>
              <div
                className={`form-wrap child ${
                  howToPosting2 === '' ? 'hide' : ''
                }`}
              >
                <div className="field">
                  <label className="label">縦</label>
                  <div className="control" wovn-ignore="true">
                    <input
                      className={
                        errors.id_14_flyer_height ? 'input error' : 'input'
                      }
                      type="text"
                      placeholder="ピクセル"
                      name="id_14_flyer_height"
                      ref={register(
                        howToPosting2.length > 0 && {
                          required: '必須項目です',
                        }
                      )}
                    />
                    <span>PX</span>
                  </div>
                  {errors.id_14_flyer_height && (
                    <p className="help is-alert">
                      {errors.id_14_flyer_height.message}
                    </p>
                  )}
                </div>
                {/* INPUT15: id_15_flyer_width */}
                <div className="field">
                  <label className="label">横</label>
                  <div className="control" wovn-ignore="true">
                    <input
                      className={
                        errors.id_15_flyer_width ? 'input error' : 'input'
                      }
                      type="text"
                      placeholder="ピクセル"
                      name="id_15_flyer_width"
                      ref={register(
                        howToPosting2.length > 0 && {
                          required: '必須項目です',
                        }
                      )}
                    />
                    <span>PX</span>
                  </div>
                  {errors.id_15_flyer_width && (
                    <p className="help is-alert">
                      {errors.id_15_flyer_width.message}
                    </p>
                  )}
                </div>
                {/* INPUT16: id_16_flyer_file_ext */}
                <div className="field">
                  <label className="label">指定ファイル</label>
                  <div className="control" wovn-ignore="true">
                    <input
                      className={
                        errors.id_16_flyer_file_ext ? 'input error' : 'input'
                      }
                      type="text"
                      placeholder="JPEG/PNGなど"
                      name="id_16_flyer_file_ext"
                      ref={register(
                        howToPosting2.length > 0 && {
                          required: '必須項目です',
                        }
                      )}
                    />
                  </div>
                  {errors.id_16_flyer_file_ext && (
                    <p className="help is-alert">
                      {errors.id_16_flyer_file_ext.message}
                    </p>
                  )}
                </div>
                {/* INPUT17: id_17_flyer_number_of_circulation */}
                <div className="field">
                  <label className="label">発行部数</label>
                  <div className="control" wovn-ignore="true">
                    <input
                      className={
                        errors.id_17_flyer_number_of_circulation
                          ? 'input error'
                          : 'input'
                      }
                      type="text"
                      placeholder="200"
                      name="id_17_flyer_number_of_circulation"
                      ref={register(
                        howToPosting2.length > 0 && {
                          required: '必須項目です',
                        }
                      )}
                    />
                    <span>部</span>
                  </div>
                  {errors.id_17_flyer_number_of_circulation && (
                    <p className="help is-alert">
                      {errors.id_17_flyer_number_of_circulation.message}
                    </p>
                  )}
                </div>
                {/* INPUT18: id_18_flyer_is_manuscript */}
                <div className="field">
                  <label className="label">原稿掲載</label>
                  <div
                    className="control is-expanded has-icons-left has-icons-right"
                    wovn-ignore="true"
                  >
                    <input type="hidden" value={flyerIsManuscript} />
                    <label className="radio" htmlFor="flyerIsManuscriptYes">
                      <input
                        type="radio"
                        value="必要"
                        name="id_18_flyer_is_manuscript"
                        id="flyerIsManuscriptYes"
                        onChange={(e) => setFlyerIsManuscript(e.target.value)}
                        ref={register(
                          howToPosting2.length > 0 && {
                            required: '必須項目です',
                          }
                        )}
                      />
                      掲載原稿が必要
                    </label>
                    <label className="radio" htmlFor="flyerIsManuscriptNo">
                      <input
                        type="radio"
                        value="不要"
                        name="id_18_flyer_is_manuscript"
                        id="flyerIsManuscriptNo"
                        onChange={(e) => setFlyerIsManuscript(e.target.value)}
                        ref={register(
                          howToPosting2.length > 0 && {
                            required: '必須項目です',
                          }
                        )}
                      />
                      掲載原稿が不要
                    </label>
                  </div>
                  {errors.id_18_flyer_is_manuscript && (
                    <p className="help is-alert">
                      {errors.id_18_flyer_is_manuscript.message}
                    </p>
                  )}
                </div>
                {/* INPUT19: id_19_flyer_number_of_chars_manuscript */}
                <div className="field">
                  <label className="label">原稿文字数</label>
                  <div className="control" wovn-ignore="true">
                    <input
                      className={
                        errors.id_19_flyer_number_of_chars_manuscript
                          ? 'input error'
                          : 'input'
                      }
                      type="text"
                      placeholder="400"
                      name="id_19_flyer_number_of_chars_manuscript"
                      ref={register(
                        howToPosting2.length > 0 && flyerIsManuscript === '必要'
                          ? {
                              required: '必須項目です',
                            }
                          : null
                      )}
                    />
                    <span>文字</span>
                  </div>
                  {errors.id_19_flyer_number_of_chars_manuscript && (
                    <p className="help is-alert">
                      {errors.id_19_flyer_number_of_chars_manuscript.message}
                    </p>
                  )}
                </div>
                {/* INPUT20: id_20_flyer_manuscript_deadline */}
                <div className="field">
                  <label className="label">原稿締切日</label>
                  <div
                    className="control is-expanded has-icons-left has-icons-right date-wrap"
                    wovn-ignore="true"
                  >
                    <input
                      type="hidden"
                      value={moment(howToPosting2Date).format('Y-M-D')}
                    />
                    <DatePicker
                      className={
                        !howToPosting2Date ? 'input date error' : 'input date'
                      }
                      dateFormat="yyyy年MM月dd日"
                      selected={howToPosting2Date}
                      onChange={(e) => handleDateHowToPosting2(e)}
                      locale="ja"
                      name="id_20_flyer_manuscript_deadline"
                      id="ApplicationDate"
                      showDisabledMonthNavigation
                      ref={register}
                    />
                    <span className="icon is-small is-left">
                      <i className="fa fa-calendar-check" />
                    </span>
                  </div>
                  {howToPosting2.length > 0 && howToPosting2Date === '' ? (
                    <p className="help is-alert">必須項目です</p>
                  ) : null}
                </div>
              </div>
              {/* INPUT21: id_21_sns_height */}
              <label className={`label ${howToPosting3 === '' ? 'hide' : ''}`}>
                SNSでの紹介: 必要サイズ・詳細{' '}
                <span className="tag is-rounded is-danger">必須</span>
              </label>
              <div
                className={`form-wrap child ${
                  howToPosting3 === '' ? 'hide' : ''
                }`}
              >
                <div className="field">
                  <label className="label">縦</label>
                  <div className="control" wovn-ignore="true">
                    <input
                      className={
                        errors.id_21_sns_height ? 'input error' : 'input'
                      }
                      type="text"
                      placeholder="ピクセル"
                      name="id_21_sns_height"
                      ref={register(
                        howToPosting3.length > 0 && {
                          required: '必須項目です',
                        }
                      )}
                    />
                    <span>PX</span>
                  </div>
                  {errors.id_21_sns_height && (
                    <p className="help is-alert">
                      {errors.id_21_sns_height.message}
                    </p>
                  )}
                </div>
                {/* INPUT22: id_22_sns_width */}
                <div className="field">
                  <label className="label">横</label>
                  <div className="control" wovn-ignore="true">
                    <input
                      className={
                        errors.id_22_sns_width ? 'input error' : 'input'
                      }
                      type="text"
                      placeholder="ピクセル"
                      name="id_22_sns_width"
                      ref={register(
                        howToPosting3.length > 0 && {
                          required: '必須項目です',
                        }
                      )}
                    />
                    <span>PX</span>
                  </div>
                  {errors.id_22_sns_width && (
                    <p className="help is-alert">
                      {errors.id_22_sns_width.message}
                    </p>
                  )}
                </div>
                {/* INPUT23: id_23_sns_file_ext */}
                <div className="field">
                  <label className="label">指定ファイル</label>
                  <div className="control" wovn-ignore="true">
                    <input
                      className={
                        errors.id_23_sns_file_ext ? 'input error' : 'input'
                      }
                      type="text"
                      placeholder="JPEG/PNGなど"
                      name="id_23_sns_file_ext"
                      ref={register(
                        howToPosting3.length > 0 && {
                          required: '必須項目です',
                        }
                      )}
                    />
                  </div>
                  {errors.id_23_sns_file_ext && (
                    <p className="help is-alert">
                      {errors.id_23_sns_file_ext.message}
                    </p>
                  )}
                </div>
                {/* INPUT24: id_24_sns_is_manuscript */}
                <div className="field">
                  <label className="label">原稿掲載</label>
                  <div
                    className="control is-expanded has-icons-left has-icons-right"
                    wovn-ignore="true"
                  >
                    <input type="hidden" value={snsIsManuscript} />
                    <label className="radio" htmlFor="snsIsManuscriptYes">
                      <input
                        type="radio"
                        value="必要"
                        name="id_24_sns_is_manuscript"
                        id="snsIsManuscriptYes"
                        onChange={(e) => setSnsIsManuscript(e.target.value)}
                        ref={register(
                          howToPosting3.length > 0 && {
                            required: '必須項目です',
                          }
                        )}
                      />
                      掲載原稿が必要
                    </label>
                    <label className="radio" htmlFor="snsIsManuscriptNo">
                      <input
                        type="radio"
                        value="不要"
                        name="id_24_sns_is_manuscript"
                        id="snsIsManuscriptNo"
                        onChange={(e) => setSnsIsManuscript(e.target.value)}
                        ref={register(
                          howToPosting3.length > 0 && {
                            required: '必須項目です',
                          }
                        )}
                      />
                      掲載原稿が不要
                    </label>
                  </div>
                  {errors.id_24_sns_is_manuscript && (
                    <p className="help is-alert">
                      {errors.id_24_sns_is_manuscript.message}
                    </p>
                  )}
                </div>
                {/* INPUT25: id_25_sns_number_of_chars_manuscript */}
                <div className="field">
                  <label className="label">原稿文字数</label>
                  <div className="control" wovn-ignore="true">
                    <input
                      className={
                        errors.id_25_sns_number_of_chars_manuscript
                          ? 'input error'
                          : 'input'
                      }
                      type="text"
                      placeholder="400"
                      name="id_25_sns_number_of_chars_manuscript"
                      ref={register(
                        howToPosting3.length > 0 && snsIsManuscript === '必要'
                          ? {
                              required: '必須項目です',
                            }
                          : null
                      )}
                    />
                    <span>文字</span>
                  </div>
                  {errors.id_25_sns_number_of_chars_manuscript && (
                    <p className="help is-alert">
                      {errors.id_25_sns_number_of_chars_manuscript.message}
                    </p>
                  )}
                </div>
                {/* INPUT26: id_26_sns_manuscript_deadline */}
                <div className="field">
                  <label className="label">原稿締切日</label>
                  <div
                    className="control is-expanded has-icons-left has-icons-right date-wrap"
                    wovn-ignore="true"
                  >
                    <input
                      type="hidden"
                      value={moment(howToPosting3Date).format('Y-M-D')}
                    />
                    <DatePicker
                      className={
                        !howToPosting2Date ? 'input date error' : 'input date'
                      }
                      dateFormat="yyyy年MM月dd日"
                      selected={howToPosting3Date}
                      onChange={(e) => handleDateHowToPosting3(e)}
                      locale="ja"
                      name="id_26_sns_manuscript_deadline"
                      id="ApplicationDate"
                      showDisabledMonthNavigation
                      ref={register}
                    />
                    <span className="icon is-small is-left">
                      <i className="fa fa-calendar-check" />
                    </span>
                  </div>
                  {howToPosting3.length > 0 && howToPosting3Date === '' ? (
                    <p className="help is-alert">必須項目です</p>
                  ) : null}
                </div>
              </div>
              {/* INPUT27: id_27_use_of_sponsored_products */}
              <div className="field">
                <label className="label">
                  協賛品の用途{' '}
                  <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div
                  className="control is-expanded has-icons-left has-icons-right"
                  wovn-ignore="true"
                >
                  <input
                    className={
                      errors.id_27_use_of_sponsored_products
                        ? 'input error'
                        : 'input'
                    }
                    type="text"
                    placeholder="協賛品の用途"
                    name="id_27_use_of_sponsored_products"
                    ref={register({
                      required: '必須項目です',
                    })}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-gavel" />
                  </span>
                </div>
                {errors.id_27_use_of_sponsored_products && (
                  <p className="help is-alert">
                    {errors.id_27_use_of_sponsored_products.message}
                  </p>
                )}
              </div>
              {/* INPUT28: id_28_expected_number_of_visitors */}
              <div className="field">
                <label className="label">
                  見込み来場者数{' '}
                  <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div
                  className="control is-expanded has-icons-left has-icons-right control-flex"
                  wovn-ignore="true"
                >
                  <input
                    className={
                      errors.id_28_expected_number_of_visitors
                        ? 'input error'
                        : 'input'
                    }
                    type="text"
                    placeholder="1200"
                    name="id_28_expected_number_of_visitors"
                    ref={register({
                      required: '必須項目です',
                    })}
                  />
                  <span>人</span>
                  <span className="icon is-small is-left">
                    <i className="fa fa-users" />
                  </span>
                </div>
                {errors.id_28_expected_number_of_visitors && (
                  <p className="help is-alert">
                    {errors.id_28_expected_number_of_visitors.message}
                  </p>
                )}
              </div>
              {/* INPUT29: id_29_remarks */}
              <div className="field">
                <label className="label">備考 </label>
                <div className="control" wovn-ignore="true">
                  <textarea
                    className="textarea"
                    placeholder="2000文字以内"
                    name="id_29_remarks"
                    maxLength="2000"
                    ref={register}
                  />
                </div>
              </div>

              <div className="field">
                <div className="btn-group">
                  <button
                    type="button"
                    className="btn-def reverse"
                    onClick={reset}
                  >
                    <span>クリア</span>
                  </button>
                  <button
                    type="submit"
                    className={`btn-def submit ${submit ? 'spin' : ''}`}
                    onClick={validationPost}
                  >
                    <span>進む</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="notification is-danger is-light sub-desc mt-4">
            <h4 className="title is-6 mb-3">注意事項</h4>
            <p className="mb-4">
              1.協賛の可否につきましては、応募後1週間以内にメールにてご連絡いたします。
              <br />
              2.協賛品は遅くとも学園祭開催日の1週間前までに送付いたします。
              <br />
              3.メールアドレスはPCのアドレスをご入力ください。
              <br />
              4.直接企画書をお送りいただいてもお受けできませんのでご了承ください。
              <br />
              5.お問い合わせはメールにて下記へお願いいたします。
            </p>
            <p className="pl-3">
              お問い合わせ{` `}
              <Link to="/inquiry/form">
                https://e-zofukuoka.com/inquiry/form/
              </Link>
            </p>
          </div>
          <div className="btn-wrap mt-5">
            <Button href="/">トップへ戻る</Button>
          </div>
          <div className="gakusai mt-5">
            <div className="flex">
              <Image src="img-gakusai-1.jpg" className="half" />
              <Image src="img-gakusai-2.jpg" className="half" />
            </div>
            <div className="flex">
              <Image src="img-gakusai-3.jpg" className="half" />
              <Image src="img-gakusai-4.jpg" className="half" />
            </div>
            <div className="flex">
              <Image src="img-gakusai-5.jpg" className="half" />
              <Image src="img-gakusai-6.jpg" className="half" />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default useFormPage;
